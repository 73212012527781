import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"


function Imprint (props) {
	const md = props.data.allFile.edges[0].node.childMarkdownRemark
	return (
		<Layout>
			<div className="about" dangerouslySetInnerHTML={{ __html: md.html }}/>
		</Layout>
	)
}

export default Imprint;

export const content = graphql`
{
	allFile(filter: {name: {eq : "imprint"}, internal: {mediaType: {eq: "text/markdown"}}, absolutePath: {regex : "\/content/"}}){
		edges{
			node{
				name
				childMarkdownRemark{
					html
					id
					frontmatter{
						name
					}
				}
			}
		}
	}
}
`